// Correspondence Methods
export enum CorrespondenceMethods {
  Email = 'Email',
  Letter = 'Letter',
  SMS = 'SMS',
  PushNotification = 'PushNotification',
}

// Correspondence Methods
export enum CorrespondenceStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Retry = 'Retry',
  Successful = 'Successful',
  Failed = 'Failed',
}

// Correspondence Providers
export enum CorrespondenceProviders {
  SendGrid = 'SendGrid',
  Twilio = 'Twilio',
  Lob = 'Lob',
}
