import type { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Logo } from './logo';
import { keyframes } from '@emotion/react';

export const SplashScreen: FC = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: theme?.palette?.neutral?.[900],
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        padding: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000,
      }}
    >
      <Logo width={'300'} height={'300'} />
    </div>
  );
};
