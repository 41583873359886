export enum MessageTypes {
  Updates = 'Updates',
  ActionRequired = 'Action Required',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Blocked = 'Blocked',
  Invited = 'Invited',
  Pending = 'Pending',
}
