import logLevelData from './log-level';
import pino, { Logger } from 'pino';
import { logflarePinoVercel } from 'pino-logflare';

const logLevels = new Map<string, string>(Object.entries(logLevelData));

export function getLogLevel(logger: string): string {
  return logLevels.get(logger) || logLevels.get('*') || 'info';
}

export function getLogger(name: string): Logger {
  let logflareStream: any, logflareSend: any;

  try {
    const { stream, send } = logflarePinoVercel({
      apiKey: process.env.NEXT_PUBLIC_LOGFLARE_API_KEY || '',
      sourceToken: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_TOKEN || '',
    });

    logflareStream = stream;
    logflareSend = send;
  } catch (error) {
    console.error(error);
  }

  // create pino logger
  const logger = pino(
    {
      name,
      browser: {
        transmit: {
          level: process.env.NEXT_PUBLIC_SOURCE_LOG_LEVEL || 'info',
          send:
            process.env.NEXT_PUBLIC_SOURCE_LOG_LEVEL !== 'silent' &&
            logflareSend
              ? logflareSend
              : () => {},
        },
      },
      level: getLogLevel(name),
      base: {
        env: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
        revision: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      },
    },
    logflareStream,
  );

  return logger;
}
