// Job Types
export enum JobTypes {
  Correspondence = 'Correspondence',
  DocuSign = 'DocuSign',
}

// Job Sub Types
export enum JobSubTypes {
  Email = 'Email',
  Lob = 'Lob',
  DocuSignToken = 'DocuSignToken',
  DocuSignEnvelopeStatus = 'DocuSignEnvelopeStatus',
}

// Job Subject
export enum JobSubject {
  User = 'User',
  Claim = 'Claim',
  Document = 'Document',
}

// Job Condition Status
export enum JobConditionStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

// Job Condition Recur Recipient
export enum JobConditionRecurRecipients {
  Claimant = 'Claimant',
  Respondent = 'Respondent',
  SuperAdmin = 'SuperAdmin',
  TenantAdmin = 'TenantAdmin',
  Litigant = 'Litigant',
  User = 'User',
}

// Job Event Attempt Status
export enum JobStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
  Skipped = 'Skipped',
  Retry = 'Retry',
}

// Job Event Attempt Status
export enum JobEventAttemptStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
  Skipped = 'Skipped',
}
