import { useContext } from "react";
import { AppAbilityContext } from "src/contexts/app-ability-context";
import UserContext from "src/contexts/user-context";
import { buildAbilityForSubject } from "src/lib/ability";

export function AppAbility(props) {
  const { currentUser, ability } = useContext(UserContext);

  return (
    <AppAbilityContext.Provider value={ability}>
      {props.children}
    </AppAbilityContext.Provider>
  );
}
