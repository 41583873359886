/* react component that imports the crisp chat script */
import { useContext, useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { EventTrackerCtx } from 'src/contexts/event-tracker';
import {
  ActivityLogEventType,
  ActivityLogSubjects,
} from '@ej-lincoln/shared-resources';
import _ from 'lodash';
import { getEnumKeyByEnumValue } from 'src/utils/get-enum-key';
import { getLogger } from 'src/pino/log-util';

export const CrispChat = () => {
  const { track } = useContext(EventTrackerCtx);
  const logger = getLogger('app');

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID) {
      Crisp.configure(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID);
      Crisp.setPosition('left');
    } else {
      logger.warn('Crisp website id not found');
    }
  });

  Crisp.chat.onChatInitiated(() => {
    // Executed once the chat was initiated from the user
    track({
      name: 'CRISP: Chat initiated',
      subject: ActivityLogSubjects.App,
      subjectRef: '',
      eventType: getEnumKeyByEnumValue(
        ActivityLogEventType,
        ActivityLogEventType.CrispEvent,
      ),
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      appEnvironment:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
      event: `Crisp chat initiated`,
    });
  });

  Crisp.chat.onChatOpened(() => {
    // Executed once the chat was opened
    track({
      name: 'CRISP: Chat opened',
      subject: ActivityLogSubjects.App,
      subjectRef: '',
      eventType: getEnumKeyByEnumValue(
        ActivityLogEventType,
        ActivityLogEventType.CrispEvent,
      ),
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      appEnvironment:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
      event: `Crisp chat opened`,
    });
  });

  Crisp.chat.onChatClosed(() => {
    // Executed once the chat was closed
    track({
      name: 'CRISP: Chat closed',
      subject: ActivityLogSubjects.App,
      subjectRef: '',
      eventType: getEnumKeyByEnumValue(
        ActivityLogEventType,
        ActivityLogEventType.CrispEvent,
      ),
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      appEnvironment:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
      event: `Crisp chat opened`,
    });
  });

  Crisp.message.onMessageSent(data => {
    // Executed once a message is submitted by the visitor
    track({
      name: 'CRISP: Message Sent',
      subject: ActivityLogSubjects.App,
      subjectRef: '',
      eventType: getEnumKeyByEnumValue(
        ActivityLogEventType,
        ActivityLogEventType.CrispEvent,
      ),
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      appEnvironment:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
      event: `Crisp: Message sent: ${data?.content}`,
      dontTrack: true,
    });
  });

  return null;
};
