import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';

interface ISnackbarProps {
  message: string;
  open?: boolean;
  type: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
}

const initialState: ISnackbarProps = {
  message: '',
  open: false,
  type: 'success',
  duration: 3000,
};

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    // Action to open the snackbar
    showSnackbar(
      state: ISnackbarProps,
      action: PayloadAction<ISnackbarProps>,
    ): void {
      state.open = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
      if (action.payload.duration) {
        state.duration = action.payload.duration;
      } else {
        state.duration = initialState.duration;
      }
    },

    // Action to close the snackbar
    hideSnackbar(state: ISnackbarProps): void {
      state.open = false;
    },
  },
});

export const { reducer } = slice;

export const { showSnackbar, hideSnackbar } = slice.actions;
