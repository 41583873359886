import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import googleTagManager from '@analytics/google-tag-manager';

export const analytics = Analytics({
  app: 'Brief',
  plugins: [
    ...(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
      ? [
          mixpanelPlugin({
            token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '',
          }),
        ]
      : []),
    ...(process.env.NEXT_PUBLIC_GTM_CONTAINER_ID
      ? [
          googleTagManager({
            containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || '',
          }),
        ]
      : []),
  ],
});
