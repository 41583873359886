import React, { useContext, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Box, SnackbarContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from 'src/slices/snackbar';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}

const DirectionSnackbar = () => {
  const { open, message, type, duration } = useSelector(
    state => state.snackbar,
  );
  const dispatch = useDispatch();

  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(() => TransitionRight);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(hideSnackbar());
      }, duration);
    }
  }, [open]);

  const icons = {
    success: <CheckCircleOutlineIcon />,
    error: <CancelIcon />,
    warning: <WarningAmberIcon />,
    info: <InfoIcon />,
  };

  const colors = {
    success: '#388e3c',
    error: '#d32f2f',
    warning: '#f57c00',
    info: '#0289d1',
  };

  return (
    <Snackbar
      open={open}
      TransitionComponent={transition}
      key={transition ? transition.name : ''}
    >
      <SnackbarContent
        style={{
          backgroundColor: colors[type],
          width: '100%',
          marginBottom: '4rem',
        }}
        message={
          <Box
            id="client-snackbar"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ marginRight: 2, display: 'flex' }}>{icons[type]}</Box>{' '}
            {message}
          </Box>
        }
      />
    </Snackbar>
  );
};

export default DirectionSnackbar;
