import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { HYDRATE } from 'next-redux-wrapper';
import { objFromArray } from '../utils/obj-from-array';

// export interface InitialFile {
//   _id: string;
//   fileName: string;
//   mimeType: string;
//   fileSize: number;
//   downloadUrl?: string;
// }

export interface InitialFile {
  file: object;
}

interface InitialFilesProps {
  files: object[];
  removedFiles: string[];
}

const initialState: InitialFilesProps = {
  files: [],
  removedFiles: [],
};

const slice = createSlice({
  name: 'fileUploader',
  initialState,
  reducers: {
    setInitialFiles(
      state: InitialFilesProps,
      action: PayloadAction<object[]>,
    ): void {
      if (typeof action.payload !== undefined) {
        state.files.push(...action.payload);
      }
    },
    removeFiles(state: InitialFilesProps, action: PayloadAction<string>): void {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i]._id.toString() === action.payload) {
          state.files.splice(i, 1);
        }
      }

      if (!state.removedFiles.includes(action.payload)) {
        state.removedFiles.push(action.payload);
      }

      console.log('removedFiles', current(state.removedFiles));
    },
    clearInitialFiles(state: InitialFilesProps): void {
      state.files = [];
      state.removedFiles = [];
    },
  },
});

export const { reducer } = slice;

export const { setInitialFiles, removeFiles, clearInitialFiles } =
  slice.actions;
