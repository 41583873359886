import { combineReducers } from '@reduxjs/toolkit';
// import { reducer as calendarReducer } from '../slices/calendar';
// import { reducer as chatReducer } from '../slices/chat';
// import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as invitationUserReducer } from '../slices/invitationUser';
import { reducer as snackbarReducer } from '../slices/snackbar';
import { reducer as fileUploaderReducer } from '../slices/fileUploader';

export const rootReducer = combineReducers({
  // calendar: calendarReducer,
  // chat: chatReducer,
  // kanban: kanbanReducer,
  mail: mailReducer,
  inviationUser: invitationUserReducer,
  snackbar: snackbarReducer,
  fileUploader: fileUploaderReducer,
});
