import { useEffect } from 'react';
import type { FC } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import nProgress from 'nprogress';
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RTL } from '../components/rtl';
import { SettingsButton } from '../components/settings-button';
import { SplashScreen } from '../components/splash-screen';
import {
  SettingsConsumer,
  SettingsProvider,
} from '../contexts/settings-context';
// import { AuthConsumer, AuthProvider } from '../contexts/jwt-context';
import { AuthConsumer, AuthProvider } from '../contexts/amplify-context';
import { gtmConfig } from '../config';
import { gtm } from '../lib/gtm';
import { store } from '../store';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { ApolloProvider, useQuery } from '@apollo/client';
import client from '../lib/apollo-client';
import '../i18n';
import { UserContext, UserContextWrapper } from '../contexts/user-context';
import { AppAbility } from 'src/components/ability/app-ability';
import DirectionSnackbar from 'src/components/common/snackbar';
import { ActivityLogWrapper } from 'src/contexts/activity-log';
import { CrispChat } from 'src/components/crisp-chat';
import { EventTrackerWrapper } from 'src/contexts/event-tracker';
import { GlobalStyles } from '@mui/material';
import { SplitContextWrapper } from 'src/contexts/split-context';

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout ?? (page => page);

  //Gtm is now initialized by analytics.js in lib/analytics -> https://getanalytics.io/plugins/google-tag-manager/
  // useEffect(() => {
  //   gtm.initialize(gtmConfig);
  // }, []);

  return (
    <CacheProvider value={emotionCache}>
      <GlobalStyles
        styles={{
          ['ol > li > ol > li']: {
            listStyleType: 'lower-alpha',
          },
          ['ol > li > ol > li > ol > li']: {
            listStyleType: 'lower-roman',
          },
          ['ol > li > ol > li > ol > li > ol > li']: {
            listStyleType: 'decimal',
          },
          ['ol > li > ol > li > ol > li > ol > li > ol > li']: {
            listStyleType: 'lower-alpha',
          },
        }}
      />
      <Head>
        <title>Brief</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ReduxProvider store={store}>
        <DirectionSnackbar />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => (
                  <ThemeProvider
                    theme={createTheme({
                      direction: settings.direction,
                      responsiveFontSizes: settings.responsiveFontSizes,
                      mode:
                        settings.theme === 'system'
                          ? globalThis.matchMedia(
                              '(prefers-color-scheme: light)',
                            ).matches
                            ? 'light'
                            : 'dark'
                          : settings.theme,
                    })}
                  >
                    <RTL direction={settings.direction}>
                      <CssBaseline />
                      <Toaster position="top-center" />
                      {/* <SettingsButton /> */}
                      <AuthConsumer>
                        {auth => {
                          return !auth.isInitialized ? (
                            <SplashScreen />
                          ) : (
                            <ApolloProvider client={client}>
                              <ActivityLogWrapper>
                                <EventTrackerWrapper>
                                  <UserContextWrapper>
                                    <SplitContextWrapper>
                                      <AppAbility>
                                        {getLayout(
                                          <Component {...pageProps} />,
                                        )}
                                      </AppAbility>
                                      <CrispChat />
                                    </SplitContextWrapper>
                                  </UserContextWrapper>
                                </EventTrackerWrapper>
                              </ActivityLogWrapper>
                            </ApolloProvider>
                          );
                        }}
                      </AuthConsumer>
                    </RTL>
                  </ThemeProvider>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </CacheProvider>
  );
};

export default App;
