import PropTypes, { InferProps } from 'prop-types';
import DarkIcon from '../assets/brief-icon-dark-bg.svg';
import LightIcon from '../assets/brief-icon-light-bg.svg';
// import Image from 'next/image';

type Variant = 'light' | 'primary';

export const Logo = (props: LogoProps) => {
  const { variant, ...other } = props;

  const color = variant === 'light' ? '#1F2362' : '#FFFFFF';

  return variant === 'light' ? (
    <LightIcon {...other} />
  ) : (
    <DarkIcon {...other} />
  );
};

/**
 * Define the props for the ClaimParticipants component
 */
const LogoPropTypes = {
  variant: PropTypes.oneOf<Variant>(['light', 'primary']),
  width: PropTypes.string,
  height: PropTypes.string,
};

/**
 * Then infer the types from proptypes
 */
Logo.propTypes = LogoPropTypes;

Logo.defaultProps = {
  variant: 'primary',
  width: '100',
  height: '100',
};

type LogoProps = InferProps<typeof LogoPropTypes>;
