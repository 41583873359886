//Naming standard: <resource>.<action>
export enum PermissionAction {
  CREATE = 'create',
  READ = 'read',
  LIST = 'list',
  LIST_OWN = 'list.own',
  VIEW = 'view',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage',
  CLAIM_SERVICE_VIEW = 'service.view',
  APPROVE = 'approve',
  RESPONDENT_SUBMISSION_1 = 'respondent.submission1',
  RESPONDENT_SUBMISSION_2 = 'respondent.submission2',
  CLAIMANT_SUBMISSION_1 = 'claimant.submission1',
  CLAIMANT_SUBMISSION_2 = 'claimant.submission2',
  CLAIM_CREATE_INITIAL = 'create.initial',
  CLAIM_START_CLAIM = 'start.claim',
  CLAIM_ATTACHMENT_VIEW = 'attachment.view',
  CLAIM_MESSAGE_INIT = 'message.init',
  CLAIM_MESSAGE_REPLY = 'message.reply',
  CLAIM_MESSAGE_VIEW = 'message.view',
  SECURITY_PREFERENCES_UPDATE = 'security.preferences.update',
  SECURITY_PREFERENCES_VIEW = 'security.preferences.view',
  SECURITY_PASSWORD_CHANGE = 'security.password.change',
  VIEW_PROFILE_EDIT = 'view.profile.edit',
  VIEW_PROFILE = 'view.profile',
  JUDGE_AWARD_INIT = 'award.init',
  JUDGE_AWARD_VIEW = 'award.view',
  CLAIM_CLEAR_CONFLICTS = 'judge.clearConflicts',
}
