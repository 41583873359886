import React, { createContext, useContext } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import UserContext from './user-context';
import _ from 'lodash';
import { SplashScreen } from 'src/components/splash-screen';

const SplitContext = createContext({});

export function SplitContextWrapper({ children }) {
  const { currentUser, loadingUser } = useContext(UserContext);
  // Create the config for the SDK factory.
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: process.env.NEXT_PUBLIC_SPLIT_AUTHORIZATION_KEY || '',
      key: 'key', //Setting default key value to avoid error
    },
  };

  if (loadingUser) {
    return <SplashScreen />;
  }
  return (
    <SplitContext.Provider value={{}}>
      <SplitFactory
        config={{ core: { ...sdkConfig.core, key: currentUser?._id } }}
        attributes={{ hostname: window.location.hostname }}
      >
        {children}
      </SplitFactory>
    </SplitContext.Provider>
  );
}

export default SplitContext;
