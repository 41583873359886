import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { HYDRATE } from 'next-redux-wrapper';
import { objFromArray } from '../utils/obj-from-array';

interface UserInfo {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  invitationCode: string;
}

const initialState: UserInfo = {
  id: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  invitationCode: '',
};

const slice = createSlice({
  name: 'invitationUser',
  initialState,
  reducers: {
    // Action to set the invitation user
    setInvitationUser(state: UserInfo, action: PayloadAction<UserInfo>): void {
      console.log('action >>> ', action);
      console.log('state >>>>> ', state.id);
      state = action.payload;

      console.log('state after >>>>> ', state.id);
    },
  },
});

export const { reducer } = slice;

export const { setInvitationUser } = slice.actions;

// export const setInvitationUser = (data: any): AppThunk => async (dispatch): Promise<void> => {
//   console.log('sdfsdfsfsdsdfsdfsdffsd');
//   dispatch(slice.actions.setInvitationUser(data));
// };
