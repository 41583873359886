import {
  AbilityBuilder,
  buildMongoQueryMatcher,
  createMongoAbility,
  AnyMongoAbility,
} from '@casl/ability';
import { $eq, eq } from '@ucast/mongo2js';

const conditionsMatcher = buildMongoQueryMatcher({ $eq }, { eq });

export default function defineRulesFor(abilities: any) {
  try {
    const { can, build } = new AbilityBuilder(createMongoAbility);

    if (abilities)
      for (const ability of abilities) {
        if (JSON.stringify(ability.conditions) === '{}') {
          can(ability.action, ability.subject);
        } else {
          can(ability.action, ability.subject, ability.conditions);
        }
      }

    return build({
      detectSubjectType: object => object.__typename,
      conditionsMatcher,
    });
  } catch (error) {
    console.error(error);
  }
}
/**
 * Example use
 * <ClaimCan I="view" a={claim}> <Component/> </ClaimCan>
 * <ClaimCan I="view" this={claim}> <Component/> </ClaimCan>
 * @param abilities any
 * @returns AnyMongoAbility | undefined
 */
export function buildAbilityForSubject(
  abilities: any,
): AnyMongoAbility | undefined {
  const claimAbilities = defineRulesFor(abilities);

  return claimAbilities;
}
